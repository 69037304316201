import React, { useEffect, useState, lazy, Suspense } from "react";
import {
  CpButton,
  CpLoader,
  CpModal,
  CpInput,
  CpRadio,
  CpSelectSingle,
  CprDatepicker,
  CpCheckbox,
  CpTooltip,
  CpIcon,
} from "canopy-styleguide!sofe";
import { isEmpty } from "lodash";
import CprMask from "cpr-mask";
import { useWithUserAndTenant, useHasAccess } from "cp-client-auth!sofe";
import { forkJoin, of } from "rxjs";
import { successToast } from "toast-service!sofe";
import { Scoped, k, t } from "kremling";
import { handleError } from "src/error";
import { featureEnabled } from "feature-toggles!sofe";
import { DateTime } from "luxon";
import { useForm, FormProvider } from "react-hook-form";

import {
  getCafs,
  createUpdateCafs,
  createClient,
  getClient,
  getClients,
  patchClient,
  patchContact,
  createContact,
  getContact,
} from "src/resources/clients.resource.js";
import {
  queueTranscriptPull,
  getIrsOrganizations,
  getSettings,
  createSettings,
  updateSettings,
  getFiscalYearBeta,
  getSuccessfulTranscriptsCount,
} from "src/resources/transcripts.resource.js";
import { notifyAnalytics } from "src/resources/analytics.resource";
import {
  defaultSettings,
  getISODateNow,
  fromISOtoShortDate,
  isExpiredDate,
  fromJSDateToISO,
  formatCafNumber,
} from "./common/settings.helper";
import {
  getActiveOrgs,
  routeToIrs,
  isOrgActive,
} from "../common/irs-status.component";

import PullSchedule from "./common/pull-schedule.component";
import OrganizationsDropdown from "./common/organizations-dropdown.component";
import IrsRequirements from "./common/irs-requirements-modal.component";
import ManageFormsSelection from "./common/manage-forms-selection.component";

const CreateClientWell = lazy(async () => {
  const clientsUI = await SystemJS.import("clients-ui!sofe");
  return await clientsUI.getCreateClientWell();
});

const clientType = {
  new: "new",
  existing: "existing",
};

const clientTypeOptions = [
  {
    id: clientType.existing,
    name: "Existing client",
  },
  {
    id: clientType.new,
    name: "New client",
  },
];

const transcriptType = {
  individual: "individual",
  business: "business",
};

const TranscriptRequestDialog = ({
  show,
  onClose,
  fetchOrganizations,
  clientId,
  preselectedContactId,
  callback,
  observer,
  transcriptsCount: transcriptsCountProp,
  requestOrigin,
}) => {
  const [businessName, setBusinessName] = useState("");
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedClientType, setSelectedClientType] = useState(
    clientTypeOptions[0]
  );
  const [clientSearchValue, setClientSearchValue] = useState("");
  const [contactSearchValue, setContactSearchValue] = useState("");
  const [selectedContact, setSelectedContact] = useState(null);

  const [selectedTranscriptType, setSelectedTranscriptType] = useState(
    transcriptType.individual
  );
  const [taxPayerId, setTaxPayerId] = useState("");
  const [isBusiness, setIsBusiness] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [cafNumber, setCafNumber] = useState(null);
  const [organizations, setOrganizations] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [requested, setRequested] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showing, setShowing] = useState(false);
  const [settings, setSettings] = useState(defaultSettings);
  const [cafs, setCafs] = useState([]);
  const [taxPayerIdUpdated, setTaxPayerIdUpdated] = useState(false);
  const [hasFiscalYearBeta, setHasFiscalYearBeta] = useState(false);
  const [transcriptsCount, setTranscriptsCount] = useState(null);
  const [showIrsRequirements, setShowIrsRequirements] = useState(false);
  const [newClientTranscript, setNewClientTranscript] = useState(false);

  const [user, tenant] = useWithUserAndTenant();

  const canEditClient = useHasAccess("clients_create_edit");
  const hasTranscriptsPull = useHasAccess("transcripts_pull");
  const hasUnlimitedPulls = useHasAccess("transcripts_pull_unlimited");

  const startingDateShort = fromISOtoShortDate(settings.starting_at);
  const shortDateNow = fromISOtoShortDate(getISODateNow());
  const limitReached =
    transcriptsCount?.successful_count >= transcriptsCount?.limit;
  const activeOrgs = getActiveOrgs(organizations);
  const isNewCrm =
    featureEnabled("ft_crm") && tenant?.crm_status === "crm_hierarchy_complete";

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      isBusiness: false,
      isNewContact: true,
    },
  });

  useEffect(() => {
    methods.resetField("tin");
  }, [methods.getValues("isBusiness")]);

  useEffect(() => {
    if (show) {
      setLoading(true);
      if (observer) {
        setShowing(true);
      }
      const subscription = forkJoin([
        getCafs(user.id),
        getIrsOrganizations(),
        getFiscalYearBeta(),
      ]).subscribe(
        ([cafsList, organizations, hasFiscalYearBeta]) => {
          const validOrgs = organizations?.filter((org) => !org.deleted_at);
          let selectedOrg = null;
          if (validOrgs?.length === 1) {
            selectedOrg = validOrgs[0];
          }

          const activeOrgs = getActiveOrgs(organizations);
          const reqsSeenDateTime = localStorage.getItem(
            "irs-requirements-seen"
          );
          const reqsSeenToday =
            reqsSeenDateTime &&
            DateTime.fromISO(reqsSeenDateTime).startOf("day").toISO() ===
              DateTime.now().startOf("day").toISO();
          const hideIrsReqs = localStorage.getItem("hide-irs-requirements");

          if (
            (!hideIrsReqs && !activeOrgs.length && !reqsSeenToday) ||
            !validOrgs.length
          ) {
            // show irs requirements
            setShowIrsRequirements(true);
            setOrganizations(validOrgs);
          }

          if ((hideIrsReqs || reqsSeenToday) && !activeOrgs.length) {
            // automatically open irs sign in
            closeModal();
            return routeToIrs(fetchOrganizations);
          }

          let cafNumber = null;
          let cafs = [];
          if (!isEmpty(cafsList)) {
            cafs = cafsList.filter(
              (cafObj) => !!cafObj.caf_number && !cafObj.deleted
            );
            if (cafs?.length === 1) {
              // default to first caf in list if there is only one
              cafNumber = cafs[0].caf_number;
            }
          }

          setCafNumber(cafNumber);
          setOrganizations(validOrgs);
          setSelectedOrg(selectedOrg);
          setLoading(false);
          setCafs(cafs);
          setHasFiscalYearBeta(hasFiscalYearBeta);

          if (clientId) {
            getClientInfo(clientId);
          }
        },
        (err) => {
          setLoading(false);
          handleError(err);
        }
      );

      return () => subscription.unsubscribe();
    }
  }, [show, clientId, fetchOrganizations, observer, user.id]);

  useEffect(() => {
    if (
      !transcriptsCountProp &&
      !transcriptsCount &&
      hasTranscriptsPull &&
      !hasUnlimitedPulls
    ) {
      fetchTranscriptsCount();
    } else if (transcriptsCountProp && !transcriptsCount) {
      setTranscriptsCount(transcriptsCountProp);
    }
  }, [
    transcriptsCountProp,
    hasTranscriptsPull,
    hasUnlimitedPulls,
    transcriptsCount,
  ]);

  useEffect(() => {
    if (selectedClient && ((isNewCrm && !requested) || !isNewCrm)) {
      const contactId =
        preselectedContactId ||
        selectedClient.contacts?.find((c) => c.is_primary)?.id;
      getTranscriptsSettings(
        selectedClient.id,
        selectedClient.is_business,
        contactId
      );
    }
  }, [
    requested,
    preselectedContactId,
    selectedClient,
    selectedClientType.id,
    isNewCrm,
  ]);

  const renderOrganizations = () => {
    return (
      <OrganizationsDropdown
        organizations={organizations}
        selectedOrg={selectedOrg}
        setSelectedOrg={setSelectedOrg}
      />
    );
  };

  const renderMultipleCafs = () => {
    const cafObj = cafs.find(
      (caf) => caf.id === settings.caf_id || caf.caf_number === cafNumber
    );

    return (
      <div className="cp-mb-16">
        <label className="cp-flex cp-mb-4">
          CAF number<span className="cp-color-app-primary"> *</span>
        </label>
        <div style={{ width: "16rem" }}>
          {isEmpty(cafs) ? (
            <CprMask
              inputClass="input-style-override fs-mask"
              initialValue={cafNumber}
              masks={[
                {
                  condition: () => true,
                  pattern: "1111-11111",
                },
              ]}
              onChange={onCafChanged}
              placeholder="____-_____"
              validateMethod={(value) => !value || value.length > 8}
              invalidClass="invalid-cpr-mask"
              nonValidMsg="CAF requires 9 digits"
            />
          ) : (
            <CpSelectSingle
              data={cafs}
              appendTo="parent"
              className="select-single-caf_override fs-exclude"
              placeholder="Select a CAF"
              onChange={(caf) => {
                setCafNumber(caf.caf_number);
                setSettings({ ...settings, caf_id: caf.id });
              }}
              value={cafObj}
              transformData={(caf) => ({
                ...caf,
                name: formatCafNumber(caf.caf_number),
              })}
              contentWidth="sm"
              triggerIsBlock
            />
          )}
        </div>
      </div>
    );
  };

  const renderClientType = () => {
    return (
      <div className="cp-mt-8 cp-flex-column">
        <div style={{ width: "26.5rem" }}>
          <label>Client type</label>
          <CpSelectSingle
            data={clientTypeOptions}
            placeholder="Client type"
            onChange={clientTypeChanged}
            value={selectedClientType}
            triggerIsBlock
          />
        </div>
      </div>
    );
  };

  const renderNewCrmClient = () => {
    return selectedClientType.id === clientType.new ? (
      <div className="cp-flex cp-mv-16">
        <Suspense fallback={<></>}>
          <CreateClientWell
            showSSN={true}
            optionalRequiredFields={{
              tin: true,
              primaryContact: !methods.getValues("isBusiness"),
              ssn: !methods.getValues("isBusiness"),
            }}
          />
        </Suspense>
      </div>
    ) : (
      <div className="cp-flex-column cp-mt-16">
        <div>
          <label>
            Client<span className="cp-color-app-primary"> *</span>
          </label>
          <CpSelectSingle
            className="select-single_override"
            clearable
            disabled={!!clientId}
            data={clients}
            placeholder="Select"
            onChange={clientChanged}
            searchOnChange={clientSearchInputChanged}
            searchValue={clientSearchValue}
            value={selectedClient}
            transformData={(client) => ({
              ...client,
              icon: client.is_business ? "misc-buildings" : "person",
            })}
            triggerIsBlock
          />
        </div>
        <div className="cp-flex cp-mt-16">
          <div className="cp-flex-column cp-mr-16">
            <label>
              Contact<span className="cp-color-app-primary"> *</span>
            </label>
            <CpSelectSingle
              className="select-single_override"
              clearable
              data={selectedClient?.contacts || []}
              placeholder="Select"
              onChange={contactChanged}
              searchOnChange={setContactSearchValue}
              searchValue={contactSearchValue}
              value={selectedContact}
              disabled={!selectedClient}
              triggerIsBlock
            />
          </div>
          {renderTaxPayerId()}
        </div>
      </div>
    );
  };

  const renderClient = () => {
    return (
      <>
        {isNewCrm && selectedTranscriptType !== transcriptType.business ? (
          renderNewCrmClient()
        ) : (
          <>
            {selectedClientType.id === clientType.new &&
              renderTransactionRequestType()}
            {selectedClientType.id === clientType.new ? (
              selectedTranscriptType === transcriptType.business ? (
                renderNewBusinessForm()
              ) : (
                renderNewIndividualForm()
              )
            ) : (
              <div className="cp-flex cp-mt-16">
                <div className="cp-flex-column cp-mb-16 cp-mr-16">
                  <label>
                    Client name<span className="cp-color-app-primary"> *</span>
                  </label>
                  <CpSelectSingle
                    className="select-single_override"
                    clearable
                    data={clients}
                    placeholder="Search for client"
                    onChange={clientChanged}
                    searchOnChange={clientSearchInputChanged}
                    searchValue={clientSearchValue}
                    value={selectedClient}
                    disabled={!!clientId}
                    transformData={(client) => ({
                      ...client,
                      icon: client.is_business ? "misc-buildings" : "person",
                    })}
                    triggerIsBlock
                  />
                </div>
                {renderTaxPayerId()}
              </div>
            )}
          </>
        )}
      </>
    );
  };

  const renderNewIndividualForm = () => {
    return (
      <div>
        <div className="cp-mv-16 cp-flex">
          <div>
            <label>Legal first name</label>
            <CpInput
              value={firstName}
              onChange={setFirstName}
              style={{ width: "16rem" }}
            />
          </div>
          <div className="cp-ml-12">
            <label>Middle</label>
            <CpInput
              value={middleName}
              onChange={setMiddleName}
              className="middle-name"
              style={{ width: "9.6rem" }}
            />
          </div>
          <div className="cp-ml-12">
            <label>Legal last name</label>
            <div>
              <CpInput
                value={lastName}
                onChange={setLastName}
                style={{ width: "16rem" }}
              />
            </div>
          </div>
        </div>
        {renderTaxPayerId()}
      </div>
    );
  };

  const renderNewBusinessForm = () => {
    return (
      <div className="cp-mt-16 cp-flex">
        <div>
          <label>Legal business name</label>
          <CpInput
            value={businessName}
            onChange={setBusinessName}
            style={{ marginRight: "1.6rem", width: "26.5rem" }}
          />
        </div>
        {renderTaxPayerId()}
      </div>
    );
  };

  const renderTransactionRequestType = () => {
    return (
      <div className="cp-mt-16">
        <label>Request as:</label>
        <CpRadio
          name="requestType"
          value={selectedTranscriptType}
          onChange={requestTypeChanged}
          inline
        >
          <CpRadio.Item id={transcriptType.individual}>Individual</CpRadio.Item>
          <CpRadio.Item id={transcriptType.business}>Business</CpRadio.Item>
        </CpRadio>
      </div>
    );
  };

  const renderOptions = () => {
    return (
      <div>
        {(featureEnabled("toggle_transcripts_fiscal_year") ||
          hasFiscalYearBeta) &&
          (selectedClient?.is_business ||
            selectedTranscriptType === transcriptType.business ||
            methods.getValues("isBusiness")) && (
            <div className="fiscal-select">
              <CpCheckbox
                onChange={setCalendar}
                checked={!settings.is_calendar}
              />
              <div style={{ marginTop: "3px" }}>
                Taxpayer is or has been a fiscal year filer
                <CpTooltip
                  text="Taxpayer filed for a period of 12 consecutive months that do not end on the last day of December."
                  position="top"
                >
                  <CpIcon
                    name="information-circle-open-small"
                    fill="var(--cp-color-app-icon)"
                  />
                </CpTooltip>
              </div>
            </div>
          )}
        <div style={{ width: "21rem", marginTop: "0.8rem" }}>
          <label>POA expiration (optional)</label>
          <CprDatepicker
            id="POA_expiration"
            date={settings.poa_expiration_at}
            events={{
              datechange: (date) => updatePOAExpiration(date.detail),
            }}
            orientation="bottom"
            removeDateOption
            removeDateText="Remove expiration date"
          />
        </div>
        <ManageFormsSelection
          className="cp-pt-24"
          saveSettings={saveSettings}
          transcriptSettings={settings}
          isBusiness={selectedTranscriptType === transcriptType.business}
          newClient={selectedClientType.id === clientType.new}
          requestModal
        />
        <PullSchedule setSettingsObj={setSettings} settings={settings} />
      </div>
    );
  };

  const saveSettings = (settings) => setSettings(settings);

  const renderTaxPayerId = () => {
    return (
      <div className="cp-mb-16">
        <label>
          {selectedTranscriptType == transcriptType.individual
            ? "SSN/ITIN"
            : "EIN"}
          <span className="cp-color-app-primary"> *</span>
        </label>
        <div style={{ width: "16rem" }}>
          <CprMask
            initialValue={taxPayerId}
            onChange={(taxPayerId) => updateTaxPayerId(taxPayerId)}
            inputProps={
              selectedTranscriptType === transcriptType.business
                ? { placeholder: "__-_______" }
                : { placeholder: "___-__-____" }
            }
            inputClass="input-style-override fs-mask"
            masks={getTINMask()}
            validateMethod={(value) => !value || value.length > 8}
            invalidClass="invalid-cpr-mask"
            nonValidMsg={`${
              selectedTranscriptType == transcriptType.individual
                ? "SSN/ITIN"
                : "EIN"
            } requires 9 digits`}
          />
        </div>
      </div>
    );
  };

  const getClientInfo = (clientId) => {
    getClient(clientId).subscribe((client) => {
      setSelectedClient(client);
      (!isNewCrm || client.is_business) &&
        setTaxPayerId(taxPayerId || client.tin);
      setFirstName(client.first_name);
      setMiddleName(client.middle_name);
      setLastName(client.last_name);
      setIsBusiness(client.is_business);
      setBusinessName(client.business_name);
      setSelectedTranscriptType(
        client.is_business ? transcriptType.business : transcriptType.individual
      );
      updateForm(client);
    }, handleError);
  };

  const getContactInfo = (contactId) => {
    getContact(contactId).subscribe((contact) => {
      setSelectedContact(contact);
      setTaxPayerId(contact.tin);
    }, handleError);
  };

  const getTranscriptsSettings = (clientId, isBusiness, contactId = "") => {
    getSettings(
      clientId,
      isBusiness,
      true,
      isNewCrm && selectedTranscriptType !== transcriptType.business,
      contactId
    ).subscribe((settings) => {
      const caf_id =
        cafs?.length === 1 && !settings?.caf_id ? cafs[0].id : settings?.caf_id;
      const cafObj = cafs.find((caf) => caf.id === caf_id);
      if (settings) {
        const selectedOrg = organizations.find(
          (org) => org.id === settings.irs_token_id
        );

        setSettings({
          ...settings,
          forms: settings.forms ? JSON.parse(settings.forms) : null,
          caf_id,
        });
        setCafNumber(cafObj?.caf_number || "");
        setSelectedOrg(selectedOrg);
        setNewClientTranscript(!settings?.has_transcripts);
      } else {
        setSettings({ ...defaultSettings, caf_id });
        setCafNumber(cafObj?.caf_number || "");
        setNewClientTranscript(true);
      }

      if (isNewCrm && !isBusiness && !!settings.contact_id) {
        getContactInfo(settings.contact_id);
      } else if (isNewCrm && !isBusiness && contactId) {
        getContactInfo(contactId);
      }
    }, handleError);
  };

  const fetchTranscriptsCount = () => {
    getSuccessfulTranscriptsCount().subscribe((transcriptsCount) => {
      setTranscriptsCount(transcriptsCount);
    }, handleError);
  };

  const onCafChanged = (value) => {
    setCafNumber(value?.replace(/\s/g, ""));
  };

  const clientSearchInputChanged = (value) => {
    setClientSearchValue(value);
    if (value.length > 1) {
      getClients(value, "20", isNewCrm).subscribe(setClients, handleError);
    }
  };

  const clientChanged = (selectedClient) => {
    setSelectedContact(null);
    if (selectedClient) {
      setSelectedClient(selectedClient);
      updateForm(selectedClient);
    } else {
      setSelectedClient(null);
    }
  };

  const contactChanged = (selectedContact) => {
    if (selectedContact) {
      getTranscriptsSettings(
        selectedClient.id,
        selectedClient.is_business,
        selectedContact.id
      );
    } else {
      setSelectedContact(null);
      setTaxPayerId("");
      setSelectedOrg();
      setCafNumber(null);
      setSettings(defaultSettings);
    }
  };

  const updateTaxPayerId = (taxPayerId) => {
    setTaxPayerId(taxPayerId);
    setTaxPayerIdUpdated(true);
  };

  const getTINMask = () => {
    return selectedTranscriptType === transcriptType.business
      ? [
          {
            pattern: "11-1111111",
            condition: () => true,
          },
        ]
      : [
          {
            pattern: "111-11-1111",
            condition: () => true,
          },
        ];
  };

  const clientTypeChanged = (selectedClientType) => {
    setSelectedClientType(selectedClientType);
    setSelectedContact(null);
    setTaxPayerId("");
    setSelectedTranscriptType(transcriptType.individual);
    methods.reset();

    if (selectedClientType.id === clientType.new) {
      const caf_id = cafs?.length === 1 ? cafs[0].id : settings.caf_id;

      setTaxPayerId("");
      setSelectedClient(null);
      setSettings({ ...defaultSettings, caf_id });
    }
  };

  const requestTypeChanged = (selectedTranscriptType) => {
    setSelectedTranscriptType(selectedTranscriptType);
    setFirstName("");
    setMiddleName("");
    setLastName("");
    setBusinessName("");
    setSettings({ ...settings, is_calendar: true });
  };

  const isValidForm = () => {
    const selectedOrgIsValid = isOrgActive(selectedOrg);

    const scheduleEntered = !settings.cadence ? true : !!settings.starting_at;
    const isBusiness =
      selectedClientType.id === clientType.existing
        ? selectedClient?.is_business
        : methods.getValues("isBusiness");

    const clientEntered =
      methods.formState.isValid ||
      (selectedClientType.id === clientType.existing
        ? !!selectedClient
        : selectedTranscriptType === transcriptType.individual
        ? !!firstName && !!lastName
        : !!businessName);
    const contactSelected = isNewCrm
      ? selectedClientType.id === clientType.new // if new client, then check if all required fields are filled out
        ? // if individual, then existing contact selection or new contact fields required
          (!isBusiness &&
            methods.getValues("isNewContact") &&
            methods.getValues("firstName") &&
            methods.getValues("lastName")) ||
          (!isBusiness &&
            !methods.getValues("isNewContact") &&
            !!methods.getValues("contact")) ||
          isBusiness
        : isBusiness || !!selectedContact
      : true;
    const cafEntered = cafNumber?.length >= 9;
    const taxPayerIdValid =
      (!isBusiness &&
        (methods.getValues("ssn") || !!methods.getValues("contact"))) ||
      (isBusiness && methods.getValues("tin")) ||
      taxPayerId?.length === 9;

    return (
      contactSelected &&
      cafEntered &&
      clientEntered &&
      taxPayerIdValid &&
      !!selectedOrg &&
      scheduleEntered &&
      selectedOrgIsValid
    );
  };

  const updateForm = (selectedClient) => {
    const taxPayerId =
      isNewCrm && !selectedClient.is_business
        ? ""
        : selectedClient.tin ||
          (selectedClient.is_business
            ? selectedClient.ein
            : selectedClient.ssn);

    if (selectedClient) {
      setTaxPayerId(taxPayerId);
      setSelectedTranscriptType(
        selectedClient.is_business
          ? transcriptType.business
          : transcriptType.individual
      );
    } else {
      setTaxPayerId(null), setSelectedTranscriptType(transcriptType.individual);
    }
  };

  const updatePOAExpiration = (date) => {
    setSettings({
      ...settings,
      poa_expiration_at: !date ? "" : fromJSDateToISO(date),
    });
  };

  const requestNewTranscripts = (
    isNewClient = false,
    newClient,
    existingContactId
  ) => {
    const requestClient = isNewClient ? newClient : selectedClient;
    const onSuccess = () => {
      const newCaf = isEmpty(cafs) && cafNumber;
      callback?.(isNewClient, newCaf);
      observer?.next?.();
      closeModal(
        selectedClientType.id === clientType.new || newClientTranscript
          ? requestClient.id
          : null
      );
    };

    const isBusiness =
      selectedClientType.id === clientType.existing
        ? selectedClient?.is_business
        : methods.getValues("isBusiness");

    const requestTranscripts = (settingsFailed) => {
      let body;

      if (!isNewCrm) {
        body = {
          client_id: requestClient.id,
        };
      } else {
        const contactId = isBusiness
          ? null
          : isNewClient
          ? methods.getValues("isNewContact")
            ? requestClient.contacts?.[0].id
            : methods.getValues("contact")?.id
          : selectedContact?.id;

        body = {
          client_and_contact_map: [
            {
              client_id: requestClient.id,
              contact_id: contactId || null,
            },
          ],
        };
      }

      queueTranscriptPull(body).subscribe(
        () => {
          if (settingsFailed || !settings?.cadence) {
            successToast("Your request to IRS e-Services has been submitted.");
          } else {
            successToast(
              "Your transcripts request schedule has successfully been saved and a request to pull transcripts has been sent."
            );
          }
          onSuccess();
        },
        (err) => {
          setRequested(false);
          handleError(err);
        }
      );
    };

    let settingsCall = createSettings;

    if (settings.id) {
      settingsCall = updateSettings;
    }

    const settingsBody = {
      ...settings,
      client_id: requestClient.id,
      irs_token_id: selectedOrg?.id,
      contact_id: isBusiness
        ? undefined
        : existingContactId ||
          (isNewClient ? requestClient.contacts?.[0].id : selectedContact?.id),
    };

    // call createUpdateCafs when a new cafs needs to be created in the db, so we can then use the id of that caf to set settings.caf_id
    (isEmpty(cafs) && cafNumber
      ? createUpdateCafs([{ caf_number: cafNumber }], user.id)
      : of([])
    ).subscribe(
      (newCafs) => {
        // if a new caf was created then set the caf_id in settings to that caf id returned from backend
        if (newCafs?.length) settingsBody.caf_id = newCafs[0]?.id;

        // settings need to be updated before we request the new transcript
        settingsCall(settingsBody, true).subscribe(
          () => {
            requestTranscripts();
          },
          (err) => {
            requestTranscripts(true);
            setRequested(false);
            handleError(err);
          }
        );
      },
      (err) => {
        requestTranscripts(true);
        setRequested(false);
        handleError(err);
      }
    );
  };

  const onNext = async (data) => {
    setRequested(true);

    if (selectedClientType.id === clientType.new) {
      if (isNewCrm) {
        const client = await createNewCrmClient(data);
        setTaxPayerId(client.ssn);
        setSelectedClient(client);
        requestNewTranscripts(true, client, data?.contact?.id);
      } else {
        createClient(getClientData()).subscribe((client) => {
          setSelectedClient(client);
          requestNewTranscripts(true, client);
        }, handleError);
      }
    } else {
      if (
        taxPayerIdUpdated &&
        canEditClient &&
        (!isNewCrm || selectedClient.is_business)
      ) {
        patchClient(selectedClient.id, { tin: taxPayerId }).subscribe(
          () => requestNewTranscripts(),
          handleError
        );
      } else if (
        taxPayerIdUpdated &&
        canEditClient &&
        isNewCrm &&
        !selectedClient.is_business
      ) {
        patchContact(selectedContact.id, {
          ...selectedContact,
          tin: taxPayerId,
        }).subscribe(() => requestNewTranscripts(), handleError);
      } else {
        requestNewTranscripts();
      }

      if (clientId) {
        notifyAnalytics(
          `transcripts.client_level_request${
            requestOrigin ? `_${requestOrigin}` : ""
          }`,
          {
            client_type: isBusiness ? "business" : "individual",
          }
        );
      }
    }
  };

  const getClientData = () => {
    let newClient = {
      business_name: "",
      first_name: "",
      last_name: "",
      tin: taxPayerId,
      is_business: selectedTranscriptType === transcriptType.business,
    };

    let clientSpecifics =
      selectedTranscriptType === transcriptType.business
        ? {
            business_name: businessName,
          }
        : {
            first_name: firstName,
            middle_name: middleName,
            last_name: lastName,
          };

    return { ...newClient, ...clientSpecifics };
  };

  const createNewCrmClient = async (clientInfo) => {
    const contactDto = {
      first_name: clientInfo.firstName,
      last_name: clientInfo.lastName,
      tin: clientInfo.ssn,
      emails: clientInfo.email
        ? [
            {
              is_primary: true,
              key: clientInfo.emailType,
              value: clientInfo.email,
            },
          ]
        : undefined,
    };

    const clientDto = {
      name: clientInfo.clientName,
      business_name: clientInfo.businessName,
      client_type: "client",
      is_active: true,
      is_business: clientInfo.isBusiness,
      tin: clientInfo.tin,
      contacts: [{ id: clientInfo.contact?.id, contact_type: "primary" }],
    };

    if (!clientDto.contacts[0].id) {
      if (clientInfo.firstName) {
        const response = await createContact(contactDto).toPromise();
        clientDto.contacts = [
          { id: response.contact?.id, contact_type: "primary" },
        ];
      } else {
        delete clientDto.contacts;
      }
    }

    return await createClient(clientDto).toPromise();
  };

  const closeModal = (newClientId) => {
    onClose?.(newClientId);
    reset();
    setLoading(false);
  };

  const reset = () => {
    setBusinessName("");
    setClients([]);
    setSelectedClient(null);
    setSelectedContact(null);
    setContactSearchValue("");
    setSelectedClientType(clientTypeOptions[0]);
    setClientSearchValue("");
    setSelectedTranscriptType(transcriptType.individual);
    setTaxPayerId("");
    setFirstName("");
    setMiddleName("");
    setLastName("");
    setCafNumber(null);
    setOrganizations([]);
    setSelectedOrg(null);
    setRequested(false);
    setLoading(true);
    setSettings(defaultSettings);
    setCafs([]);
    setTaxPayerIdUpdated(false);
    setHasFiscalYearBeta(false);
    setTranscriptsCount(null);
    setShowIrsRequirements(false);
    setNewClientTranscript(false);
    methods.reset();
  };

  const setCalendar = (isFiscal) => {
    setSettings({ ...settings, is_calendar: !isFiscal });
  };

  return showIrsRequirements ? (
    <IrsRequirements
      show={showIrsRequirements}
      close={() => {
        closeModal();
        setShowIrsRequirements(false);
      }}
      fetchOrganizations={fetchOrganizations}
      orgsSaved={organizations.length}
    />
  ) : (
    <FormProvider {...methods}>
      <CpModal show={showing || show} onClose={closeModal} width={500}>
        <CpModal.Header title="Request IRS e-Services Transcripts" />
        <CpModal.Body>
          {loading ? (
            <div className="cp-pt-40">
              <CpLoader />
            </div>
          ) : (
            <Scoped css={css}>
              <div className="tr-subheader">Request Details</div>
              {!clientId && renderClientType()}
              {renderClient()}
              <div className="cp-flex">
                {renderOrganizations()}
                {renderMultipleCafs()}
              </div>
              {renderOptions()}
            </Scoped>
          )}
        </CpModal.Body>
        <CpModal.Footer>
          <div className="cp-flex-spread-center">
            <div>
              <CpButton
                btnType="primary"
                onClick={() => {
                  if (isNewCrm) {
                    methods.handleSubmit((data) => {
                      onNext(data);
                    })();
                  } else {
                    onNext();
                  }
                }}
                disabled={
                  !isValidForm() ||
                  requested ||
                  limitReached ||
                  !activeOrgs.length
                }
              >
                {!settings.cadence ||
                startingDateShort === shortDateNow ||
                isExpiredDate(settings.starting_at)
                  ? "Request transcripts"
                  : "Schedule transcripts request"}
              </CpButton>
              <CpButton className="cp-ml-8" btnType="flat" onClick={closeModal}>
                Cancel
              </CpButton>
            </div>
            {transcriptsCount && (
              <div
                className={t(
                  "cp-color-app-warning-text",
                  "cp-color-app-secondary-text",
                  limitReached
                )}
              >
                {transcriptsCount.successful_count}/{transcriptsCount.limit}{" "}
                Transcripts Pulled
                {!limitReached && (
                  <>
                    <CpTooltip
                      position="top"
                      interactive
                      text={
                        <>
                          Trial accounts allow for a maximum of 3 successful
                          transcripts requests. This limit is shared between all
                          team members on an account. For unlimited requests,{" "}
                          <a
                            className="cp-color-app-primary-dark-text"
                            href="/#/global-settings/account-management"
                          >
                            <u>upgrade</u>
                          </a>{" "}
                          in your account management settings.
                        </>
                      }
                    >
                      <CpIcon name="information-circle-open-small" />
                    </CpTooltip>
                  </>
                )}
              </div>
            )}
          </div>
        </CpModal.Footer>
      </CpModal>
    </FormProvider>
  );
};

export default TranscriptRequestDialog;

const css = k`
  .tr-subheader {
    color: var(--cp-color-primary-text);
    font-weight: bold;
  }

  .middle-name {
    width: 4rem;
  }

  .request-authorization {
    color: var(--cp-color-app-primary-text);
    margin: 2.4rem 4rem;
    text-align: center;
  }

  .input-style-override {
    display: block;
    width: 100%;
    height: 3.2rem;
    padding: 0.2rem 1.2rem 0.1rem 1.2rem;
    font-size: 1.3rem;
    background-color: #fff;
    background-image: none;
    border: 0.1rem solid #dfdfdf;
    border-radius: 0.5rem;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  }

  .select-single_override {
    width: 26.5rem;
  }

  .select-single_override > div > button {
    max-width: 26.5rem;
  }

  .select-single-caf_override {
    width: 16rem;
  }

  .select-single-caf_override > div > button {
    max-width: 16rem;
  }

  .invalid-cpr-mask {
    color: red;
  }

  .invalid-cpr-mask input {
    border: 0.1rem solid red;
  }

  .fiscal-select {
    margin-bottom: 1.6rem;
    display: flex;
    align-items: center;
  }
`;
