import canopyUrls from "canopy-urls!sofe";
import { infoToast } from "toast-service!sofe";
import { noop, isEmpty } from "lodash";

export const transcriptsPrint = (
  version,
  filtersObject = {},
  componentStateCallBack = noop
) => {
  componentStateCallBack();
  // Open New window and print
  let screenWidth =
    window.screenWidth != undefined ? window.screenWidth : screen.width;
  const filters = !isEmpty(filtersObject)
    ? `?years=${JSON.stringify(filtersObject.years)}&types=${JSON.stringify(
        filtersObject.types
      )}`
    : "";

  const printWindow = window.open(
    `${canopyUrls.getWorkflowUrl()}/api/transcripts/${
      version.id
    }/records/html${filters}`,
    "Print_Transcripts_Documents",
    `top=${window.screenTop}, left=${screenWidth}, width=800, height=800`
  );

  printWindow.focus();
  printWindow.print();
  printWindow.addEventListener(
    "load",
    () => (printWindow.document.title = "Transcripts Document"),
    { once: true }
  );

  !printWindow &&
    infoToast({
      message:
        "The print window didn't open, if you are using a pop-up blocking utility then it may have blocked it.",
      durationInMillis: 6000,
    });
};

export const reportsPrint = (resourceUrl) => {
  let screenWidth =
    window.screenWidth != undefined ? window.screenWidth : screen.width;

  // Open a new window
  let printWindow = window.open(
    resourceUrl,
    "Print_Transcripts_Reports",
    `top=${window.screenTop},left=${screenWidth},width=800,height=800`
  );

  // Open print window
  if (printWindow) {
    printWindow.focus();
    printWindow.print();
  } else {
    infoToast({
      message:
        "The print window didn't open, if you are using a pop-up blocking utility then it may have blocked it.",
      durationInMillis: 6000,
    });
  }
};

export const transcriptsDownload = (
  versionId,
  filtersObject = {},
  componentStateCallback = noop
) => {
  componentStateCallback();
  const filters = !isEmpty(filtersObject)
    ? `?years=${JSON.stringify(filtersObject.years)}&types=${JSON.stringify(
        filtersObject.types
      )}`
    : "";
  window.location = `${canopyUrls.getWorkflowUrl()}/api/transcripts/${versionId}/records/zip${filters}`;
};

export const reportsDownload = (versionId, filtersObject = {}, isBusiness) => {
  const filters = !isEmpty(filtersObject)
    ? `&types=${JSON.stringify(filtersObject.types)}`
    : "";
  window.location = `${canopyUrls.getWorkflowUrl()}/api/transcripts/${versionId}/reports/zip?is_business=${!!isBusiness}${filters}`;
};
