import React, { useState } from "react";
import { Scoped, k } from "kremling";
import { CpButton, CpModal, CpCheckbox, CpRadio } from "canopy-styleguide!sofe";
import canopyUrls from "canopy-urls!sofe";

export default function ReportsPrintDownloadModal({
  download,
  print,
  close,
  action,
  versionId,
  showPrintDownloadModal,
  isBusiness,
  reportTypeOptions,
}) {
  const [allTypes, setAllTypes] = useState(true);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const removeType = (type) =>
    setSelectedTypes(selectedTypes.filter((set) => set !== type));
  const addType = (type) => setSelectedTypes([...selectedTypes, type]);
  const buttonActive = allTypes || selectedTypes.length;
  const headText = action === "download" ? "Download" : "Print";
  const onClose = () => {
    setSelectedTypes([]);
    setAllTypes(true);
    close();
  };
  const submit = () => {
    action === "download"
      ? download(allTypes, selectedTypes)
      : print(
          `${canopyUrls.getWorkflowUrl()}/api/transcripts/${versionId}/reports/pdf?is_business=${!!isBusiness}&types=${JSON.stringify(
            selectedTypes
          )}`
        );
    return onClose();
  };

  return (
    <CpModal show={showPrintDownloadModal} onClose={onClose} width={500}>
      <CpModal.Header title={`${headText} Reports`} />
      <CpModal.Body>
        <Scoped css={css}>
          <div className="cps-subheader-sm">
            Select the report(s) you would like to {headText.toLowerCase()}
          </div>
          <div className="reports-selection">
            <CpRadio
              onChange={(value) => setAllTypes(value === "all-types")}
              value={allTypes ? "all-types" : "individual-types"}
              name="all-types"
            >
              <CpRadio.Item id="all-types">All reports</CpRadio.Item>
              <CpRadio.Item id="individual-types">
                Select individual reports
              </CpRadio.Item>
            </CpRadio>
          </div>
          {!allTypes && (
            <div className="individual-reports">
              {reportTypeOptions.map((reportType) => {
                const checked = selectedTypes.includes(reportType.value);
                return (
                  <div key={reportType.value} className="report-option">
                    <CpCheckbox
                      checked={checked}
                      onChange={(checked) => {
                        if (checked) {
                          addType(reportType.value);
                        } else {
                          removeType(reportType.value);
                        }
                      }}
                    >
                      {reportType.label}
                    </CpCheckbox>
                  </div>
                );
              })}
            </div>
          )}
        </Scoped>
      </CpModal.Body>
      <CpModal.Footer>
        <CpButton btnType="primary" disabled={!buttonActive} onClick={submit}>
          {headText}
        </CpButton>
        <CpButton btnType="flat" onClick={onClose}>
          Cancel
        </CpButton>
      </CpModal.Footer>
    </CpModal>
  );
}

const css = k`
  .report-option {
    margin-bottom: 8px;
  }

  .reports-selection {
    padding: 16px 0;
  }

  .individual-reports {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 0px 40px 8px;
  }
`;
